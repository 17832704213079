.spinner {
	position: relative;
	height: 20px;
}

.spinner.spinner--black:before {
	border: 2px solid #181c32;
	border-right: 2px solid transparent;
}

.spinner.spinner--secondary:before {
	border: 2px solid #dfe2e6;
	border-right: 2px solid transparent;
}

.spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1.5rem;
	height: 1.5rem;
	margin-top: -0.75rem;
	margin-left: -0.75rem;
	border-radius: 50%;
	border: 2px solid #fff;
	border-right: 2px solid transparent;
	-webkit-animation: animation-spinner 0.5s linear infinite;
	animation: animation-spinner 0.5s linear infinite;
}

.spinner--sm:before {
	width: 0.75rem;
	height: 0.75rem;
}

@-webkit-keyframes animation-spinner {
	to {
		transform: rotate(360deg);
	}
}
@keyframes animation-spinner {
	to {
		transform: rotate(360deg);
	}
}
