@import './Input/Input.scss';
@import './Select/Select.scss';
@import './Drawer/Drawer.scss';
@import './Snackbar/Snackbar.scss';
@import './Spinner/Spinner.scss';
@import './Radio/Radio.scss';
@import './LoaderOverlay/LoaderOverlay.scss';
@import './Tabs/Tabs.scss';
@import './Tab/Tab.scss';
@import './TabPanel/TabPanel.scss';
@import './Dropdown/Dropdown.scss';
@import './DropdownMenu/DropdownMenu.scss';
@import './DropdownItem/DropdownItem.scss';

.input--group {
    margin-bottom: 24px;
    // padding-left: 1rem;
    // padding-right: 1rem;
}
