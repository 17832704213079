.hours-item {
    padding-bottom: 1rem;
}
.hours-item:not(:first-child) {
    padding-top: 1.5rem;
}
.hours-item:not(:last-child) {
    border-bottom: 1px solid #e5e7eb;
}
.hours-item.hours-item--disabled .hours-item--details {
    opacity: 0.3;
}
.hours-item--day {
    width: 90px;
}
.time-select {
    width: 130px;
}
