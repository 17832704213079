.service {
    border-radius: 0.5rem;
    padding: 26px 20px;
    position: relative;
}

.serviceName {
    font-weight: 500;
    font-size: 1.2rem;
}

.serviceMoneyLabel {
    background-color: #00be70;
    padding: 2px 6px 2px 2px;
    display: inline-block;
    border-radius: 50px;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 6px;
}

.create_serviceBtn {
    position: absolute;
    bottom: 24px;
    right: 24px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 14px 24px 0 rgb(0 0 0 / 28%);
    background: #27292e;
    border-radius: 50%;
    color: #fff;
    font-size: 1.5rem;
}

.selectedService {
    position: relative;

    &:before {
        content: '';
        top: -2px;
        left: -2px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 2px solid #263446;
    }
}

.services_selectedService {
    border-width: 2px;
    border-style: dashed;
}
