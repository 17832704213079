/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Description: Defined style for table to manage/customise table default behaviour.
 */

.datatable-table {
    background-color: #ffffff;
    border-collapse: collapse;
    overflow: hidden;
    display: block;
    width: 100%;
    line-height: initial;
}
.datatable-table > .datatable-head,
.datatable-table > .datatable-body {
    visibility: visible;
    display: block;
    position: relative;
}
.datatable-table .datatable-head {
    border-bottom: 1px solid #ebedf3;
}
.datatable-table .datatable-row {
    width: 100%;
    border-bottom: 1px solid #ebedf3;
    display: table;
    table-layout: initial;
}
.datatable-table .datatable-row:last-child {
    border-bottom: none;
}
.datatable-table > .datatable-head .datatable-row .datatable-cell {
    padding: 1rem;
    vertical-align: middle;
    font-size: 1rem;
}
.datatable-table > .datatable-head .datatable-row .datatable-cell:first-child,
.datatable-table > .datatable-body .datatable-row .datatable-cell:first-child {
    padding-left: 0;
}
.datatable-table > .datatable-head .datatable-row .datatable-cell > span {
    text-align: left;
    font-size: 0.85rem;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 0.1rem;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #b5b5c3;
}
.datatable-table > .datatable-body .datatable-row .datatable-cell {
    vertical-align: middle;
    padding: 1rem 1rem;
    font-size: 1rem;
}
.datatable-table > .datatable-body .datatable-row .datatable-cell > span {
    color: #263446;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
