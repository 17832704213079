.drawer-right {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 100;
	box-shadow: rgb(62 53 71 / 18%) 0px 0px 48px 0px;
	min-width: 360px;
	border-top-right-radius: 0.75rem;
	border-bottom-right-radius: 0.75rem;
	transition: transform 0.3s ease-in-out;
	transform: translateX(100%);
	// width: 680px;
	width: 400px;

	&.drawer--open {
		transform: none;
	}
}
.drawer-left {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: #1a202c;
	z-index: 100;
	box-shadow: rgb(62 53 71 / 18%) 0px 0px 48px 0px;
	min-width: 100px;
	border-top-right-radius: 0.75rem;
	border-bottom-right-radius: 0.75rem;
	transition: transform 0.3s ease-in-out;
	transform: translateX(100%);
	// width: 680px;
	width: 270px;

	&.drawer--open {
		transform: none;
	}
}

.drawer--header {
	display: flex;
	text-align: center;
	padding: 1rem 1.75rem;
	min-height: 70px;

	.drawer--closeBtn {
		margin-right: 1.5rem;
		i {
			font-size: 2.25rem;
		}
	}

	.drawer--heading {
		font-size: 1.75rem;
		font-weight: 600;
	}
}
.drawer--body {
	padding: 0 1.75rem;
	overflow: hidden;
}
.single-request {
	display: flex;
	box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
	background: #fff;
	padding: 16px;
	border-radius: 0.5rem;
	width: 100%;
	border: 1px dashed #d1d5db;

	.single-request--avatar {
		background: linear-gradient(-135deg, #eee, #d8d8d8);
		color: #979797;
		width: 42px;
		height: 42px;
		border-radius: 50%;
	}

	&:not(:last-child) {
		margin-bottom: 0.75rem;
	}
}
