.appointment_formContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.appointment_addCustomer {
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px dashed #d1d5db;
    cursor: pointer;
}
.appointment_formDetails {
    padding-top: 2rem;
    height: calc(100% - 84px);
    overflow: scroll;
}
.apointment_formDate {
    font-weight: 500;
    font-size: 1.35rem;
}
.appointment_formFooter {
    @apply border-t;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 1.75rem;
    background: #fff;
}

.customer_formContainer,
.service_formContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.service_formBody {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    overflow: scroll;
}
.service_selectedService {
    @apply border rounded-lg mb-8;

    padding: 12px 12px 12px 20px;
    width: 100%;
    display: flex;
    position: relative;
    cursor: pointer;

    .service_border {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-left-width: 4px;
        border-radius: 8px 0 0 8px;
    }

    .service_serviceDetails {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .service_seviceName {
        font-weight: 500;
        font-size: 1.1rem;
    }
    .service_serviceDuration {
        @apply text-gray-700;
        font-size: 0.925rem;
    }
    .service_servicePrice {
        font-weight: 500;
        font-size: 1.1rem;
        margin-right: 18px;
    }
}
.services_selectService {
    border-width: 2px;
    border-style: dashed;
    color: red;
}
.gratuity_tip {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgb(49 47 51 / 3%);
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 5rem;
    margin-left: 0.5rem;

    &.gratuity_tipActive {
        border: 2px solid #263446;

        div {
            &:first-child {
                font-weight: 500;
            }
        }
    }
}
.gratuity-items {
    display: flex;
    justify-content: space-around;
}
.gratuity {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
}