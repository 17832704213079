.custom-calendar .rmdp-top-class,
.custom-calendar .rmdp-calendar > div:not(:first-child),
.custom-calendar .rmdp-day-picker {
    height: 100%;
}

.custom-calendar,
.custom-calendar .rmdp-calendar,
.custom-calendar .rmdp-day-picker > div {
    width: 100%;
    height: 94%;
}

.custom-calendar .rmdp-day {
    width: unset;
    height: 100%;
    flex: 1;
}
.custom-calendar .rmdp-day span {
    font-size: 16px;
}
.custom-calendar .rmdp-day {
    width: unset;
    height: 100%;
    flex: 1;
}

.custom-calendar .rmdp-week {
    height: 14.5%;
}
.rmdp-wrapper {
    width: 100% !important;
}
