.tabs--container {
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;
}

.tabs {
	display: flex;
	flex: 1;

	.tabs--list {
		display: flex;
		flex: 1;
		z-index: 1;
		padding: 0;
	}

	&.tabs--default {
		border-bottom: 1px solid #e5e7eb;
		padding-left: 24px;
		padding-right: 24px;
	}

	&.tabs--bordered {
		color: #8c8b88;
		background-color: #fafafa;
		min-height: 36px;
		border-radius: 8px;
		padding: 4px;
		display: flex;
		align-items: stretch;
		cursor: pointer;
		position: relative;
		font-size: 13px;
	}
}

.tabs--indicator {
	position: absolute;
	bottom: 0px;
	transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;

	&.tabs--bordered {
		background-color: #e4e7eb;
		bottom: 4px;
		top: 4px;
		border-radius: 6px;
	}

	&.tabs--default {
		background-color: #27292e;
		height: 2px;
		bottom: 0;
	}
}
