.colorPicker--swatch {
    border: 1px solid rgba(229, 231, 235);
    border-radius: 8px;
    display: flex;
    align-items: center;

    .swatch-color {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 36px;
        height: 36px;
        outline: 0;
        cursor: pointer;
    }

    .swatch-text {
        display: flex;
        align-items: center;
        font-size: 1.05rem;
        cursor: pointer;
        outline: 0;
    }
}
.colorPicker--popover {
    position: absolute;
    z-index: 2;
}
.pl-4 {
    padding-left: 1rem !important;
}
