.snackbar--wrapper {
	display: flex;
	align-items: center;
	margin: 8px;
	position: fixed;
	right: 0;
	left: 0;
	top: 0rem;
	z-index: 9999;
	transition: opacity 150ms, transform 150ms;
	pointer-events: none;

	&.snackbar--wrapper-bottom-center {
		// bottom: 8px;
		justify-content: center;
	}
}
.snackbar--wrapper-updated {
	top: 4rem !important;
}
.snackbar {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 4px;
	min-width: 340px;
	max-width: 670px;
	// background-color: #333;
	background-color: black;
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
	font-size: 13px;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.9);
	line-height: 20px;
	text-align: left;

	.snackbar--text {
		flex-grow: 1;
		padding: 14px 16px;
		margin: 0;
		pointer-events: auto;
	}

	.snackbar--close {
		flex-shrink: 0;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 36px;
		height: 36px;
		padding: 8px;
		margin: 0 8px 0 0;
		cursor: pointer;
		position: relative;
		pointer-events: auto;
		-webkit-tap-highlight-color: transparent;
		outline: none;
		background-color: transparent;
		border: none;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.9);

		&::before {
			content: "";
			background-color: #fff;
			border-radius: 50%;
			opacity: 0;
			position: absolute;
			transition: opacity 120ms linear;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
		}

		&:hover {
			&::before {
				opacity: 0.08;
			}
		}
	}
}
.snackbar-enter,
.snackbar-exit-active {
	opacity: 0;
}

.snackbar-enter-active {
	opacity: 1;
}

.snackbar-enter-top-left,
.snackbar-enter-top-center,
.snackbar-enter-top-right,
.snackbar-exit-active-top-left,
.snackbar-exit-active-top-center,
.snackbar-exit-active-top-right {
	transform: translateY(-16px);
}

.snackbar-enter-bottom-left,
.snackbar-enter-bottom-center,
.snackbar-enter-bottom-right,
.snackbar-exit-active-bottom-left,
.snackbar-exit-active-bottom-center,
.snackbar-exit-active-bottom-right {
	transform: translateY(16px);
}

.snackbar-enter-active-top-left,
.snackbar-enter-active-top-center,
.snackbar-enter-active-top-right,
.snackbar-enter-active-bottom-left,
.snackbar-enter-active-bottom-center,
.snackbar-enter-active-bottom-right {
	transform: translateY(0px);
}
