.dropdown--menu {
    box-shadow: 0 16px 48px 0 rgb(62 53 71 / 18%);
    position: absolute;
    z-index: 100;
    top: calc(100% + 12px);
    right: 0rem;
    background: #fff;
    border-radius: 8px;
    padding: 16px 0;
    width: 220px;

    .dropdown--scrollable {
        overflow-y: auto;
        max-height: 240px;
    }

    &::before {
        top: -11px;
        right: 16px;
        border-width: 0 10px 12px 10px;
        border-color: transparent transparent #fff transparent;
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
    }
}
